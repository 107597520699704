/* You can add global styles to this file, and also import other style files */
@import "ngx-toastr/toastr";
@import "~font-awesome/css/font-awesome.css";

.custom-class-input {
  border: none;
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
}

.error-message {
  margin-top: 0.25rem;
  color: red;
}
.ngx-foreground-spinner {
  .sk-ball-spin-clockwise {
    color: #72c052;
  }
}
button.disabled {
  cursor: not-allowed;
}
.fa-plus {
  color: white;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.custom-modal-center{
  .modal-content{
    border: none !important;
  }
}